<script setup>

import Shared from '@/common/shared'
import {Dictionary, Funds} from '@/common/api.service'
import {toRefs, defineProps, ref, onMounted} from 'vue'
import ValidateInput from '@/components/validate/input.vue'
import {useToast} from 'vue-toastification'

const toast = useToast()
const props = defineProps({
  collab: {},
  item: {},
})
const {collab, item,} = toRefs(props)
const itemFields = ref([])
const collabPrograms = ref([])
const setRef = (el) => {
  itemFields.value.push(el)
}

async function save() {
  let isValid = true
  let isNotReqValid = true
  itemFields.value.forEach((f) => {
    f.validate()
    if (!f.notRequired && f.validationFailed) {
      isValid = false
    }

    if (f.notRequired && f.validationFailed) {
      isNotReqValid = false
    }

  })

  if (collab.value.rfrpSiteUrl === '') {
    delete collab.value.rfrpSiteUrl
  }

  if (collab.value.gispSiteUrl === '') {
    delete collab.value.gispSiteUrl
  }

  if (isValid && isNotReqValid) {
    if (!collab.value.id) {
      await postNew().then(async () => {
        await Funds.collabPrograms(item.value.fund.id)
            .then(({data}) => {
              item.value.collabPrograms = data
            }).catch(() => {
            })
        await close()
      }).catch(async ({response}) => {
        toast.error(response.data.title, {
          timeout: 5000
        })
      })
    } else {
      await post().then(async () => {
        await Funds.collabPrograms(item.value.fund.id)
            .then(({data}) => {
              item.value.collabPrograms = data
            }).catch(() => {
            })
        await close()
      }).catch(async ({response}) => {
        toast.error(response.data.title, {
          timeout: 5000
        })
      })
    }
  } else {
    let textError = 'Программа не сохранена, проверьте обязательные поля'
    if (!isValid && !isNotReqValid) {
      textError = 'Программа не сохранена, проверьте обязательные поля и формат заполнения ссылок'
    }else if (!isNotReqValid) {
      textError = 'Программа не сохранена, проверьте формат заполнения ссылок'
    }
    toast.error(textError, {
      timeout: 5000
    })
  }
}

async function close() {
  Shared.closeModal('#joint-programs-form')
  Shared.closeModal('#del-joint-programs-form')
  Shared.closeModal('#deleted-contact')
  collab.value.modal = false
}

async function postNew() {
  return Funds.createCollabProgram(item.value.fund.id, {
    'type': parseInt(collab.value.type),
    'finishedAt': collab.value.finishedAt,
    'startDate': collab.value.startDate,
    'rfrpSiteUrl': collab.value.rfrpSiteUrl,
    'gispSiteUrl': collab.value.gispSiteUrl
  })
}

async function post() {
  return Funds.updateCollabProgram(item.value.fund.id, collab.value.id, {
    'finishedAt': collab.value.finishedAt,
    'type': parseInt(collab.value.type),
    'startDate': collab.value.startDate,
    'rfrpSiteUrl': collab.value.rfrpSiteUrl,
    'gispSiteUrl': collab.value.gispSiteUrl
  })
}

async function remove() {
  await Funds.removeCollabProgram(item.value.fund.id, collab.value.id)
  await Funds.collabPrograms(item.value.fund.id)
      .then(({data}) => {
        item.value.collabPrograms = data
      }).catch(() => {
      })
  Shared.showModal('#del-joint-programs-form')
  Shared.showModal('#deleted-contact')
}

async function showRemove() {
  Shared.closeModal('#joint-programs-form')
  Shared.showModal('#del-joint-programs-form')
}

onMounted(async () => {
  Shared.showModal('#joint-programs-form')
  await Dictionary.collabPrograms()
      .then(({data}) => {
        collabPrograms.value = data
      }).catch(() => {
      })
})

</script>

<template>
  <div class="modal-overlay" id="joint-programs-form">
    <div class="popup">
      <div class="modal-title title-h3" v-if="!collab.id">Добавление совместной программы</div>
      <div class="modal-title title-h3" v-else>Редактирование совместной программы</div>
      <div class="modal-body">
        <ValidateInput
            :name="'Название программы'"
            required
            nonzero
            :ref="setRef"
            select
            :items="collabPrograms"
            :item-key="'value'"
            :item-name="'title'"
            v-model="collab.type">
        </ValidateInput>
        <ValidateInput
            label-class="title-field title-field--relative"
            :name="'Дата запуска программы'"
            :ref="setRef"
            required
            v-model="collab.startDate">
          <template v-slot:field="{validationFailed}">
            <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                           :class="{unvalidate: validationFailed}"
                           v-model="collab.startDate"/>
          </template>
        </ValidateInput>
        {{}}
        <ValidateInput
            label-class="title-field title-field--relative"
            :name="'Дата прекращения программы'"
            :ref="setRef"
            v-model="collab.finishedAt">
          <template v-slot:field="{validationFailed}">
            <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                           :class="{unvalidate: validationFailed}"
                           v-model="collab.finishedAt"/>
          </template>
        </ValidateInput>
        <ValidateInput
            :name="'Ссылка на сайт РФРП'"
            placeholder="Введите ссылку на программу на сайте РФРП"
            :ref="setRef"
            url
            notRequired
            maxlength="255"
            :class="{unvalidate: validationFailed}"
            v-model="collab.rfrpSiteUrl"
        >
        </ValidateInput>
        <ValidateInput
            :name="'Ссылка на навигатор ГИСП'"
            placeholder="Введите ссылку на программу в навигаторе мер поддержки"
            :ref="setRef"
            url
            notRequired
            maxlength="255"
            :class="{unvalidate: validationFailed}"
            v-model="collab.gispSiteUrl">
        </ValidateInput>
      </div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="submit" class="button button--delete" v-if="collab.id" @click="showRemove">Удалить</button>
          <button type="reset" class="button button--clear" @click="close">
            отменить
          </button>
          <button type="submit" class="button" @click="save">СОХРАНИТЬ</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-overlay" id="del-joint-programs-form">
    <div class="popup">
      <div class="modal-title">Удаление записи</div>
      <div class="modal-body">Вы действительно хотите удалить совместную программу?</div>
      <div class="modal-bottom button-dialog-center">
        <div class="buttons-block">
          <button type="button" class="button button--clear" @click="close">Закрыть
          </button>
          <button type="submit" class="button button--delete" @click="remove">Удалить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-overlay" id="deleted-contact">
    <div class="popup">
      <div class="modal-title">Запись удалена</div>
      <div class="modal-body"></div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="button" class="button" @click="close">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>