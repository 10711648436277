<template>
  <label :class="validationFailed?labelClass +' unvalidate':labelClass">{{ required ? name?name + '*':'' : name }}
    <textarea v-if="textarea" class="field field--textarea"
              :class="{'unvalidate': validationFailed}"
              @change="change"
              :maxlength="maxlength"
              :disabled="disabled"
              :placeholder="placeholder"
              :value="value"></textarea>
    <NumberInput v-else-if="number"
                 :input-class="{'field': true, 'unvalidate': validationFailed}"
                 @change="change"
                 :disabled="disabled"
                 :placeholder="placeholder"
                 :rounded="rounded"
                 :maxlength="maxlength"
                 :float-format="floatFormat"
                 v-model="valueNumber"
    />
    <select v-else-if="select" v-model="value"
            @change="change" class="field field--select" :disabled="disabled"
            :class="{'unvalidate': validationFailed}">
      <option :value="i[itemKey]"
              :key="i[itemKey]" v-for="i in items">
        {{ i[itemName] }}
      </option>
    </select>
    <input v-else-if="!$slots.field" @change="change" class="field"
           :placeholder="placeholder"
           :maxlength="maxlength"
           :class="{'unvalidate': validationFailed}"
           :disabled="disabled" v-model="value"/>
    <slot name="field" :validationFailed="validationFailed"></slot>
  </label>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'ValidateInput',
  props: {
    textarea: Boolean,
    select: Boolean,
    number: Boolean,
    required: Boolean,
    email: Boolean,
    url: Boolean,
    notRequired: Boolean,
    nonzero: Boolean,
    canSetNull: Boolean,
    disabled: Boolean,
    rounded: Boolean,
    spaces: Boolean,
    placeholder: {
      type: String,
      default: '',
    },
    maxlength: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    labelClass: {
      type: String,
      default: 'title-field',
    },
    unselect: String,
    unselectValue: String,
    showErrors: Boolean,
    items: {
      type: Array,
    },
    itemKey: {
      type: String,
      default: '',
    },
    itemName: {
      type: String,
      default: '',
    },
    floatFormat: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    objectValidationKey: {
      type: String,
      default: '',
    },
    modelValue: {
      default: '',
    },
    validateFunc: {
      type: Function,
      default: () => {
        return true
      },
    },
  },
  data() {
    return {
      value: '',
      valueNumber: NaN,
      validationFailed: false,
      isSett: false,
      validationList: {
        required: 'Поле обязательно для заполнения',
        email: 'Неверно указан email-адрес',
        url: 'Неверно указан email-адрес',
        notRequired: ''
      }
    }
  },
  mounted() {

  },
  emits: [
    'update:modelValue',
  ],
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (this.number) {
          if (newValue === '') {
            this.valueNumber = NaN
          } else {
            this.valueNumber = newValue
          }
        } else {
          if (this.isSett) {
            this.setValue(newValue)
          } else {
            this.value = newValue
          }
        }
        this.isSett = true
      },
    },
    $props: {
      immediate: true,
      deep: true,
      handler() {
        if (this.$props.errors.length > 0) {
          this.validationFailed = true
        } else {
          this.validationFailed = false
        }
      }
    }
  },
  methods: {
    change(event) {
      if (this.number) {
        this.setValueNumber(this.valueNumber)
      } else {
        this.setValue(event.target.value)
      }
    },
    setValue(newValue) {
      let oldValue = this.value
      this.value = newValue
      this.validate()
      this.$emit('update:modelValue', newValue, oldValue)
    },
    validate() {
      this.validateFunc(this.$props.errors)
      //console.log(this.name, this.modelValue, this.$props.errors)
      if (this.required && this.canSetNull && !this.modelValue){
        let index = this.$props.errors.indexOf(this.validationList.required)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }

        if (this.modelValue !== 0){
          this.$props.errors.push(this.validationList.required)
        }

      }else if (this.required && !this.modelValue) {
        let index = this.$props.errors.indexOf(this.validationList.required)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }
        this.$props.errors.push(this.validationList.required)
      } else if (this.required && this.objectValidationKey !== '' && !this.modelValue[this.objectValidationKey]) {
        console.log(this.objectValidationKey, this.modelValue[this.objectValidationKey])
        let index = this.$props.errors.indexOf(this.validationList.required)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }
        this.$props.errors.push(this.validationList.required)
      } else if (!this.notRequired) {
        let index = this.$props.errors.indexOf(this.validationList.required)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }
      }
      if (this.email && this.modelValue) {
        let index = this.$props.errors.indexOf(this.validationList.email)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }
        if (!String(this.modelValue)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
          this.$props.errors.push(this.validationList.email)
        }
      }
      //console.log(this.name,this.nonzero, parseInt(this.modelValue))
      if (this.nonzero && (parseInt(this.modelValue) === 0 || isNaN(parseInt(this.modelValue)))) {
        let index = this.$props.errors.indexOf(this.validationList.required)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }
        this.$props.errors.push(this.validationList.required)
      }
      if (this.url && this.modelValue) {
        let index = this.$props.errors.indexOf(this.validationList.url)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }

        if (!this.validURL(this.modelValue)) {
          this.$props.errors.push(this.validationList.email)
        }

      }
      if (this.$props.errors.length > 0) {
        this.validationFailed = true
      } else {
        this.validationFailed = false
      }
    },
    validURL(str) {
      var pattern = new RegExp('^(http|https|ftp):\\/\\/'   + // проверка протокола
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'  + // проверка имени домена
          '((\\d{1,3}\\.){3}\\d{1,3}))'                       + // проверка ip адреса (версия 4, не 6)
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'                   + // проверка порта и пути
          '(\\?[;&a-z\\d%_.~+=-]*)?'                          + // проверка параметров запроса
          '(\\#[-a-z\\d_]*)?$','i');


      return !!pattern.test(str);
    },
    setValueNumber(newValue) {
      if (this.required && !newValue) {
        this.$props.errors.push(this.validationList.required)
      } else {
        let index = this.$props.errors.indexOf(this.validationList.required)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }
      }
      this.$emit('update:modelValue', newValue, newValue)
    }
  }
})
</script>

<style scoped>
.unvalidate {
  border-color: red;
}
</style>