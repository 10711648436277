<script setup>

import Shared from '@/common/shared'
import {FileDocument, Funds} from '@/common/api.service'
import {toRefs, defineProps, ref, onMounted} from 'vue'
import ValidateInput from '@/components/validate/input.vue'
import {useToast} from 'vue-toastification'
import {useDropzone} from 'vue3-dropzone'

const toast = useToast()
const props = defineProps({
  uploadDocument: {},
  item: {},
})
const {uploadDocument, item} = toRefs(props)
const itemFields = ref([])
const setRef = (el) => {
  itemFields.value.push(el)
}
const docFile = ref()
const { getRootProps, getInputProps } = useDropzone({ onDrop })

function onDrop(acceptFiles) {
  docFile.value = acceptFiles[0]
}

async function onAddDocFile(e) {
  let files = e.target.files
  for (let i = 0; i < files.length; i++) {
    let file = files[i]
    docFile.value = file
  }
}

async function save() {
  let isValid = true
  itemFields.value.forEach((f) => {
    f.validate()
    if (f.validationFailed) {
      isValid = false
    }
  })

  if (!uploadDocument.value.id) {
    if (!docFile.value || !docFile.value.name) {
      toast.error('Документ не добавлен, проверьте обязательные поля и загрузите документ', {
        timeout: 5000
      })
      return
    }
  } else if (!uploadDocument.value.name) {
    if (!docFile.value || !docFile.value.name) {
      toast.error('Документ не добавлен, проверьте обязательные поля и загрузите документ', {
        timeout: 5000
      })
      return
    }
  }
  if (isValid) {
    if (!uploadDocument.value.id) {
      await postNew()
    } else {
      await post()
    }
    await close()
  } else {
    toast.error('Документ не добавлен, проверьте обязательные поля', {
      timeout: 5000
    })
  }
}

async function close() {
  Shared.closeModal('#document-form')
  Shared.closeModal('#del-document-form')
  Shared.closeModal('#close-document')
  uploadDocument.value.rfrpModal = false
}

async function postNew() {
  let formData = new FormData()
  formData.append('data', docFile.value)
  formData.append('name', docFile.value.name)
  formData.append('mimeType', docFile.value.type)
  await FileDocument.fileTemp(formData).then(async ({ data }) => {
    uploadDocument.value.name = data.name
    uploadDocument.value.temporaryFileId = data.id
    await Funds.createDocument(item.value.fund.id, uploadDocument.value)
        .then(async () => {
          await Funds.documents(item.value.fund.id)
              .then(({ data }) => {
                item.value.fundDocuments = data
              }).catch((message) => {
                console.log(message)
              })
        }).catch((message) => {
          console.log(message)
    })
  }).catch(({message}) => {
    console.log(message)
  })
}

async function post() {
  if (docFile.value && docFile.value.name) {
    let formData = new FormData()
    formData.append('data', docFile.value)
    formData.append('name', docFile.value.name)
    formData.append('mimeType', docFile.value.type)
    await FileDocument.fileTemp(formData).then(({ data }) => {
      uploadDocument.value.name = data.name
      uploadDocument.value.temporaryFileId = data.id
    }).catch(() => {

    })
  }
  Funds.updateDocument(item.value.fund.id, uploadDocument.value.id, uploadDocument.value)
      .then(async () => {
        await Funds.documents(item.value.fund.id)
            .then(({ data }) => {
              item.value.fundDocuments = data
            }).catch(() => {
            })
      }).catch(() => {
  })
}

async function remove() {
  await Funds.removeDocument(item.value.fund.id, uploadDocument.value.id)
  await Funds.documents(item.value.fund.id)
      .then(({ data }) => {
        item.value.fundDocuments = data
      }).catch(() => {
      })
  Shared.closeModal('#del-document-form')
  Shared.showModal('#close-document')
}

async function showRemove() {
  Shared.closeModal('#document-form')
  Shared.showModal('#del-document-form')
}

function removeDoc() {
  uploadDocument.value.name = ''
}

onMounted(() => {
  Shared.showModal('#document-form')
  if (uploadDocument.value.toDel) {
    showRemove()
  }
})

</script>

<template>
  <div class="modal-overlay" id="document-form">
    <div class="popup">
      <div class="modal-title title-h3" v-if="!uploadDocument.id">Добавление нового документа</div>
      <div class="modal-title title-h3" v-else>Редактирование документа</div>
      <div class="modal-body">
        <ValidateInput
            :name="'Тип документа'"
            placeholder="Введите тип документа"
            :ref="setRef"
            required
            v-model="uploadDocument.typeName">
        </ValidateInput>
        <ValidateInput
            :name="'Номер документа'"
            placeholder="Введите номер документа"
            :ref="setRef"
            required
            v-model="uploadDocument.number">
        </ValidateInput>
        <ValidateInput
            label-class="title-field title-field--relative"
            :name="'Дата'"
            :ref="setRef"
            required
            v-model="uploadDocument.date">
          <template v-slot:field="{validationFailed}">
            <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                           :class="{unvalidate: validationFailed}"
                           v-model="uploadDocument.date" />
          </template>
        </ValidateInput>
        <label for="public-doc" class="title-field">Публичный*
          <select v-model="uploadDocument.visibility" class="field field--select">
            <option :value="1">Да</option>
            <option :value="0">Нет</option>
          </select>
        </label>

        <label for="file-doc" class="title-field title-field--relative">Файл документа*
          <div class="title-field__doc" v-if="uploadDocument.name">
            <span style="margin-right: 5px">{{uploadDocument.name}}</span>
            <button type="button" class="button-del" style="display: inline" @click="removeDoc()"></button>
          </div>
          <div v-else class="label-add-file transparent" v-bind="getRootProps()">
            <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
            <span class="field field--file" v-if="!docFile || !docFile.name">Выберите или перетащите сюда файл
                  (максимум 25Мб)</span>
            <span class="field field--file" v-else>{{ docFile.name }}</span>
          </div>
        </label>
      </div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="reset" class="button button--delete" v-if="uploadDocument.id" @click="showRemove">Удалить
          </button>
          <button type="reset" class="button button--clear" @click="close">отменить
          </button>
          <button type="submit" class="button" @click="save">СОХРАНИТЬ</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-overlay" id="del-document-form">
    <div class="popup">
      <div class="modal-title">Удаление записи</div>
      <div class="modal-body">Вы действительно хотите удалить документ?</div>
      <div class="modal-bottom button-dialog-center">
        <div class="buttons-block">
          <button type="button" class="button button--clear" @click="close">Закрыть
          </button>
          <button type="submit" class="button button--delete" @click="remove">Удалить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-overlay" id="close-document">
    <div class="popup">
      <div class="modal-title">Документ удален</div>
      <div class="modal-body"></div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="button" class="button" @click="close">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>