<script setup>

import MainFooter from '@/components/partial/MainFooter.vue'
import {computed, ref} from 'vue'
import {Dictionary, Funds, Regions, Rgo} from '@/common/api.service'
import {useRoute, useRouter} from 'vue-router'
import ValidateInput from '@/components/validate/input.vue'
import moment from 'moment'
import {useToast} from 'vue-toastification'

const route = useRoute()
const router = useRouter()
const toast = useToast()
const item = ref({
  tranches: [],
  targets: [],
})
const ownPrograms = ref([])
const region = ref({})
const fund = ref({})
const isLoading = ref(false)
const counterparties = ref([])
const counterparty = ref([])
const supportedIndustries = ref([])
const loanTargets = ref([])
const itemFields = {
  agreementNumber: ref(null),
  counterparty: ref(null),
  projectTitle: ref(null),
  program: ref(null),
  targetDescription: ref(null),
  okved: ref(null),
  agreementDate: ref(null),
  fundsTransferDate: ref(null),
  comment: ref(null),
  loanSum: ref(null),
  cofinanceSum: ref(null),
  totalBudgetOfProject: ref(null),
  summaryGainOfProject: ref(null),
  summaryTaxRevenue: ref(null),
  applicationsForIntellectualPropertyObjectQuantity: ref(null),
  highPerformanceWorkbenchQuantity: ref(null),
  bankGuarantee: ref(null),
  legalEntityGuarantee: ref(null),
  individualGuarantee: ref(null),
  mspCorporationGuarantee: ref(null),
  rgoGuarantee: ref(null),
  realEstateGuarantee: ref(null),
  equipmentAndTransportGuarantee: ref(null),
  securitiesAndOtherGuarantee: ref(null),
  targets: ref(null),
}

const counterpartyList = computed(() => {
  let r = []
  counterparties.value.forEach(function (f) {
    r.push({value: f.value, label: `${f.label}`})
  })
  return r
})
const supportedIndustriesList = computed(() => {
  let r = []
  supportedIndustries.value.forEach(function (f) {
    r.push({value: f.value, label: `${f.title}`})
  })

  return r
})
const targetList = computed(() => {
  let r = []
  loanTargets.value.forEach(function (f) {
    r.push({value: f.value, label: `${f.title}`})
  })

  return r
})
const otherTarget = computed(() => {
  return item.value.targets.find(v => v === 1)
})

async function loadItem(id) {
  await Funds.ownProgramAgreement(id)
      .then(async ({data}) => {
        item.value = {
          agreementNumber: data.agreementNumber,
          counterparty: data.counterpartyId,
          projectTitle: data.projectTitle,
          program: data.program.id,
          targetDescription: data.targetDescription,
          okved: data.okved,
          agreementDate: data.agreementDate,
          fundsTransferDate: moment(data.fundsTransferDate).format('YYYY-MM-DD'),
          comment: data.comment,
          loanSum: data.loanSum,
          cofinanceSum: data.cofinanceSum,
          totalBudgetOfProject: data.totalBudgetOfProject,
          summaryGainOfProject: data.summaryGainOfProject,
          summaryTaxRevenue: data.summaryTaxRevenue,
          applicationsForIntellectualPropertyObjectQuantity: data.applicationsForIntellectualPropertyObjectQuantity,
          highPerformanceWorkbenchQuantity: data.highPerformanceWorkbenchQuantity,
          bankGuarantee: data.bankGuarantee,
          legalEntityGuarantee: data.legalEntityGuarantee,
          individualGuarantee: data.individualGuarantee,
          mspCorporationGuarantee: data.mspCorporationGuarantee,
          rgoGuarantee: data.rgoGuarantee,
          realEstateGuarantee: data.realEstateGuarantee,
          equipmentAndTransportGuarantee: data.equipmentAndTransportGuarantee,
          securitiesAndOtherGuarantee: data.securitiesAndOtherGuarantee,
          tranches: data.tranches,
          targets: data.targets,
        }
        if (data.organizationTitle && item.value.counterparty) {
          //console.log(data.organizationTitle)
          await Rgo.counterparties(data.organizationTitle).then(({data}) => {
            data.forEach(function (f) {
              counterparties.value.push({value: f, label: `${f.title} ИНН: ${f.inn} ОГРН: ${f.ogrn}`})
              //console.log(counterparties.value, item.value.counterparty, f.value)
              if (item.value.counterparty === f.value) {
                counterparty.value = {value: f, label: `${f.title} ИНН: ${f.inn} ОГРН: ${f.ogrn}`}
              }
            })

          })
        }
      }).catch(() => {
      })
}

await Regions.item(route.params.id)
    .then(({data}) => {
      region.value = data
    }).catch(() => {
    })
await Funds.item(region.value.fund.id)
    .then(({data}) => {
      fund.value = data
    }).catch(() => {
    })

await Funds.ownPrograms(region.value.fund.id)
    .then(({data}) => {
      ownPrograms.value = data
      if (data.length > 0 && !item.value.program) {
        item.value.program = data[0].id
      }
    }).catch(() => {
    })

await Dictionary.supportedIndustries()
    .then(({data}) => {
      supportedIndustries.value = data
      if (data.length > 0) {
        item.value.okved = data[0].value
      }
    }).catch(() => {
    })

await Dictionary.loanTargets()
    .then(({data}) => {
      loanTargets.value = data
    }).catch(() => {
    })

if (route.params.agreement) {
  await loadItem(route.params.agreement)
}

async function counterpartySearch(query) {
  isLoading.value = true
  counterparties.value = []
  if (query === '') {
    isLoading.value = false
    return
  }
  await Rgo.counterparties(query).then(({data}) => {
    data.forEach(function (f) {
      counterparties.value.push({value: f, label: `${f.title} ИНН: ${f.inn} ОГРН: ${f.ogrn}`})
    })
  })
  isLoading.value = false
}

async function saveOwnProgram() {
  let isValid = true
  if (item.value.tranches.length === 0) {
    isValid = false
  }
  Object.keys(itemFields).forEach((f) => {
    if (itemFields[f].value) {
      itemFields[f].value.validate()
      if (itemFields[f].value.validationFailed) {
        isValid = false
      }
    }
  })

  if (isValid) {
    item.value.counerpartyId = counterparty.value.value
    if (route.params.agreement) {
      await Funds.ownProgramsUpdateAgreement(item.value.program, route.params.agreement, item.value).then(async () => {
        await router.push({name: 'ListAgreements', query: {region: route.params.id}})
      })
    } else {
      item.value.okved = String(item.value.okved)
      await Funds.ownProgramsCreateAgreement(item.value.program, item.value).then(async () => {
        await router.push({name: 'ListAgreements', query: {region: route.params.id}})
      })
    }
  } else {
    toast.error('Договор не сохранен, проверьте обязательные поля и траншевое финансирование', {
      timeout: 5000
    })
  }
}

</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link :to="{ name: 'ListRegions' }">Модуль регионов
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'ListRegions' }">Список регионов
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'CardRegions', params: { id: region.id } }">{{ fund.region.title }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'ListAgreements', query: { region: region.id } }">Реестр договоров займа
                  по самостоятельным программам
                </router-link>
              </li>
              <li v-if="!route.params.agreement">Добавление договора займа
              </li>
              <li v-else>Редактирование договора займа
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="content__contract">
              <div class="content__title content__title--contract">
                <h1 class="title title-h1--contract">Добавление договора займа</h1>
              </div>
              <form action="#" class="contract">
                <div class="contract__row">
                  <div class="contract__item">
                    <ValidateInput
                        :name="'Номер договора займа'"
                        placeholder="Введите номер договора займа"
                        :ref="itemFields.agreementNumber"
                        required
                        v-model="item.agreementNumber">
                    </ValidateInput>
                    <ValidateInput
                        label-class="title-field title-field--relative"
                        object-validation-key="value"
                        :name="'Название организации'"
                        :ref="itemFields.counterparty"
                        required
                        v-model="counterparty">
                      <template v-slot:field="{validationFailed}">
                        <Multiselect class="field field--select c-input c-select c-filter__input c-multiselect-filter"
                                     :class="{unvalidate: validationFailed}" v-model="counterparty"
                                     mode="single" :loading="isLoading" @search-change="counterpartySearch"
                                     :min-chars="10"
                                     :show-no-results="false" :searchable="true" :options="counterpartyList"
                                     placeholder="Название или ИНН организации"
                                     :no-options-text="'Укажите ОГРН или ИНН'"/>
                        <a href="https://olk.frprf.ru/counterparty/" class="field--add-icon-green link-select-item"
                           target="_blank"></a>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Наименование проекта'"
                        placeholder="Введите наименование проекта"
                        :ref="itemFields.projectTitle"
                        required
                        textarea
                        v-model="item.projectTitle">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Программа РФРП'"
                        required
                        :ref="itemFields.program"
                        select
                        :items="ownPrograms"
                        :item-key="'id'"
                        :item-name="'title'"
                        v-model="item.program">
                    </ValidateInput>
                    <ValidateInput
                        label-class="title-field title-field--relative"
                        :name="'На какие цели предоставлен займ'"
                        :ref="itemFields.targets"
                        required
                        v-model="item.targets">
                      <template v-slot:field="{validationFailed}">
                        <Multiselect placeholder="" class="field field--select"
                                     :class="{unvalidate: validationFailed}"
                                     style="height: auto" v-model="item.targets"
                                     mode="tags" :searchable="true" :aria-expanded="true"
                                     :options="targetList"/>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="''"
                        placeholder="Комментарий"
                        :ref="itemFields.targetDescription"
                        v-if="otherTarget"
                        v-model="item.targetDescription">
                    </ValidateInput>
                    <ValidateInput
                        label-class="title-field title-field--relative"
                        :name="'ОКВЭД проекта'"
                        :ref="itemFields.okved"
                        required
                        v-model="item.okved">
                      <template v-slot:field="{validationFailed}">
                        <Multiselect placeholder="" class="field field--select"
                                     :class="{unvalidate: validationFailed}"
                                     style="height: auto" v-model="item.okved"
                                     mode="single" :searchable="true" :aria-expanded="true"
                                     :options="supportedIndustriesList"/>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        label-class="title-field title-field--relative"
                        :name="'Дата договора займа'"
                        :ref="itemFields.agreementDate"
                        required
                        v-model="item.agreementDate">
                      <template v-slot:field="{validationFailed}">
                        <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                                       :class="{unvalidate: validationFailed}"
                                       v-model="item.agreementDate"/>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        label-class="title-field title-field--relative"
                        :name="'Дата перечисления средств'"
                        :ref="itemFields.fundsTransferDate"
                        required
                        v-model="item.fundsTransferDate">
                      <template v-slot:field="{validationFailed}">
                        <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                                       :class="{unvalidate: validationFailed}"
                                       v-model="item.fundsTransferDate"/>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Комментарий'"
                        placeholder="Введите комментарий"
                        :ref="itemFields.comment"
                        textarea
                        v-model="item.comment">
                    </ValidateInput>
                  </div>
                  <div class="contract__item">
                    <ValidateInput
                        :name="'Сумма займа, млн руб.'"
                        placeholder="Введите cумму займа"
                        :ref="itemFields.loanSum"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.loanSum">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Софинансирование (частные инвестиции), млн руб.'"
                        placeholder="Введите условия софинансирования"
                        :ref="itemFields.cofinanceSum"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.cofinanceSum">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Общий бюджет проекта, млн руб.'"
                        placeholder="Введите бюджет проекта в млн руб."
                        :ref="itemFields.totalBudgetOfProject"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.totalBudgetOfProject">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Суммарный объем выручки по проекту, млн руб.'"
                        placeholder="Введите суммарный объем выручки в млн руб."
                        :ref="itemFields.summaryGainOfProject"
                        input-class="field"
                        rounded
                        required
                        number
                        v-model="item.summaryGainOfProject">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Объем налоговых поступлений, млн руб.'"
                        placeholder="Введите объем налоговых поступлений в млн руб."
                        :ref="itemFields.summaryTaxRevenue"
                        input-class="field"
                        rounded
                        required
                        number
                        v-model="item.summaryTaxRevenue">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Кол-во заявок на объекты интеллектуальной собственности, шт.'"
                        placeholder="Введите количество заявок"
                        :ref="itemFields.applicationsForIntellectualPropertyObjectQuantity"
                        input-class="field"
                        rounded
                        required
                        number
                        v-model="item.applicationsForIntellectualPropertyObjectQuantity">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Кол-во высокопроизв. рабочих мест, шт.'"
                        placeholder="Введите количество рабочих мест"
                        :ref="itemFields.highPerformanceWorkbenchQuantity"
                        input-class="field"
                        rounded
                        required
                        number
                        v-model="item.highPerformanceWorkbenchQuantity">
                    </ValidateInput>
                  </div>
                </div>
                <div class="title title--contract-subtitle">Обеспечение</div>
                <div class="contract__row">
                  <div class="contract__item">
                    <ValidateInput
                        :name="'Банковская гарантия, млн руб.'"
                        placeholder="Введите значение в млн руб."
                        :ref="itemFields.bankGuarantee"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.bankGuarantee">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Поручительство ЮЛ, млн руб.'"
                        placeholder="Введите значение в млн руб."
                        :ref="itemFields.legalEntityGuarantee"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.legalEntityGuarantee">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Поручительство физ. лиц, млн руб.'"
                        placeholder="Введите значение в млн руб."
                        :ref="itemFields.individualGuarantee"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.individualGuarantee">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Поручительство корпорации МСП, млн руб.'"
                        placeholder="Введите значение в млн руб."
                        :ref="itemFields.mspCorporationGuarantee"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.mspCorporationGuarantee">
                    </ValidateInput>
                  </div>
                  <div class="contract__item">
                    <ValidateInput
                        :name="'РГО, млн руб.'"
                        placeholder="Введите значение в млн руб."
                        :ref="itemFields.rgoGuarantee"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.rgoGuarantee">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Недвижимость, млн руб.'"
                        placeholder="Введите значение в млн руб."
                        :ref="itemFields.realEstateGuarantee"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.realEstateGuarantee">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Оборудование и транспорт, млн руб.'"
                        placeholder="Введите значение в млн руб."
                        :ref="itemFields.equipmentAndTransportGuarantee"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.equipmentAndTransportGuarantee">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Ценные бумаги и иное, млн руб.'"
                        placeholder="Введите значение в млн руб."
                        :ref="itemFields.securitiesAndOtherGuarantee"
                        input-class="field"
                        required
                        rounded
                        number
                        v-model="item.securitiesAndOtherGuarantee">
                    </ValidateInput>
                  </div>
                </div>
                <div class="tranche-financing">
                  <div class="title title--tranche-financing">Траншевое финансирование
                    <button type="button"
                            class="button-add" @click="item.tranches.push({})"></button>
                  </div>
                  <div class="table-container table-container--pad-inline">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Дата</th>
                        <th>Сумма, млн. руб.</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(i, index) in item.tranches" :key="i">
                        <td>
                          <VueDatePicker model-type="yyyy-MM-dd" format="dd.MM.yyyy" placeholder="00.00.0000"
                                         class="field field--calendar" v-model="i.date"/>
                        </td>
                        <td>
                          <NumberInput v-model="i.sum" input-class="field" :rounded="true"
                                       placeholder="Введите сумму займа"/>
                        </td>
                        <td>
                          <div class="table-button-block">
                            <button type="button"
                                    @click="item.tranches.splice(index, 1)" class="button-del"></button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="buttons-block buttons-block--right">

                  <router-link class="button button--clear"
                               :to="{ name: 'ListAgreements', query: { region: region.id } }">Отмена изменений
                  </router-link>
                  <button type="button" class="button" @click="saveOwnProgram">сохранить</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document">
        <div class="popup popup-del">
          <div class="modal-title">Удаление записи</div>
          <div class="modal-body">Вы действительно хотите удалить самостоятельную программу?</div>
          <div class="modal-bottom button-dialog-center">
            <div class="buttons-block">
              <button type="button" class="button button--clear close-modal--js">Закрыть</button>
              <button type="button" class="button button--delete sub-modal-open--js"
                      data-modal="close-document">Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="close-document">
        <div class="popup">
          <div class="modal-title">Запись удалена</div>
          <div class="modal-body"></div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button close-modal--js">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped></style>