<script setup>

import MainFooter from '@/components/partial/MainFooter.vue'
import {computed, ref} from 'vue'
import {Dictionary, Funds, Regions} from '@/common/api.service'
import {useRoute, useRouter} from 'vue-router'
import ValidateInput from '@/components/validate/input.vue'
import {useToast} from 'vue-toastification'
import moment from 'moment'
import Shared from '@/common/shared'

const route = useRoute()
const router = useRouter()
const toast = useToast()
const item = ref({})
const supportedIndustries = ref([])
const targetedUses = ref([])
const fund = ref({})
const region = ref({})
const itemFields = ref([])

const setRef = (el) => {
  itemFields.value.push(el)
}

await Regions.item(route.params.id)
    .then(({data}) => {
      region.value = data
    }).catch(() => {
    })
await Funds.item(region.value.fund.id)
    .then(({data}) => {
      fund.value = data
    }).catch(() => {
    })

if (route.params.own) {
  await Funds.ownPrograms(fund.value.id)
      .then(({data}) => {
        item.value = data.find(i => i.id === route.params.own)
        let s = []
        for (let i = 0; i < item.value.supportedIndustries.length; i++) {
          s.push(item.value.supportedIndustries[i].value)
        }
        item.value.supportedIndustries = s
        let u = []
        for (let i = 0; i < item.value.targetedUses.length; i++) {
          u.push(item.value.targetedUses[i].value)
        }
        item.value.targetedUses = u
      }).catch(() => {
      })
}

await Dictionary.supportedIndustries()
    .then(({data}) => {
      supportedIndustries.value = data
    }).catch(() => {
    })

await Dictionary.targetedUses()
    .then(({data}) => {
      targetedUses.value = data
    }).catch(() => {
    })

const supportedIndustriesList = computed(() => {
  let r = []
  supportedIndustries.value.forEach(function (f) {
    r.push({value: f.value, label: `${f.title}`})
  })

  return r
})

const targetedUsesList = computed(() => {
  let r = []
  targetedUses.value.forEach(function (f) {
    r.push({value: f.value, label: `${f.title}`})
  })

  return r
})

async function save() {
  let isValid = true
  itemFields.value.forEach((f) => {
    f.validate()
    if (f.validationFailed) {
      isValid = false
    }
  })

  if (isValid) {
    if (!item.value.id) {
      await postNew()
    } else {
      await post()
    }
  } else {
    toast.error('Программа не сохранена, проверьте обязательные поля', {
      timeout: 5000
    })
  }
}

async function postNew() {
  await Funds.createOwnPrograms(fund.value.id, item.value).then(async () => {
    await router.push({name: 'CardRegions', params: {id: route.params.id}})
  })
}

async function post() {
  await Funds.updateOwnPrograms(fund.value.id, item.value.id, item.value).then(async () => {
    await router.push({name: 'CardRegions', params: {id: region.value.id}})
  })
}

async function showRemove() {
  Shared.showModal('#del-document')
}

async function closeRemove() {
  Shared.closeModal('#del-document')
}

async function remove() {
  await Funds.removeOwnPrograms(fund.value.id, item.value.id).then(async () => {
    Shared.closeModal('#del-document')
    Shared.showModal('#close-document')
  }).catch(({response}) => {
    Shared.closeModal('#del-document')
    if (response.data.title) {
      toast.error(response.data.title, {
        timeout: 5000
      })
    } else {
      toast.error('Ошибка удаления программы', {
        timeout: 5000
      })
    }
  })
}

async function close() {
  Shared.closeModal('#close-document')
  await router.push({ name: 'CardRegions', params: { id: region.value.id } })
}

</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link
                    :to="{name: 'ListRegions'}">Модуль регионов
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{name: 'ListRegions'}">Список регионов
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{name: 'CardRegions', params: { id: fund.region.id}}">
                  {{ fund.region.title }}
                </router-link>
              </li>
              <li v-if="item.id"> Самостоятельная программа "{{ item.title }}"</li>
              <li v-else>Добавление самостоятельной программы</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="content__contract">
              <div class="content__title content__title--contract">
                <h1 class="title title-h1--contract" v-if="!item.id">Добавление самостоятельной программы</h1>
                <h1 class="title title-h1--contract" v-else>{{ fund.shortTitle }}, Самостоятельная программа "{{item.title}}"</h1>
              </div>
              <form action="#" class="contract">
                <div class="contract__row">
                  <div class="contract__item">
                    <ValidateInput
                        :name="'Название самостоятельной программы'"
                        placeholder="Введите название программы"
                        :ref="setRef"
                        required
                        maxlength="255"
                        v-model="item.title">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Описание программы'"
                        placeholder="Введите описание программы"
                        :ref="setRef"
                        required
                        textarea
                        maxlength="2000"
                        v-model="item.description">
                    </ValidateInput>
                    <ValidateInput
                        label-class="title-field title-field--relative"
                        :name="'Поддерживаемые отрасли'"
                        :ref="setRef"
                        required
                        v-model="item.supportedIndustries">
                      <template v-slot:field="{validationFailed}">
                        <Multiselect
                            placeholder="Введите название отрасли"
                            class="field field--select" style="height: auto"
                            v-model="item.supportedIndustries"
                            :class="{unvalidate: validationFailed}"
                            mode="tags"
                            :searchable="true"
                            :aria-expanded="true"
                            :options="supportedIndustriesList"
                        />
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        label-class="title-field title-field--relative"
                        :name="'Направление целевого использования'"
                        :ref="setRef"
                        required
                        v-model="item.targetedUses">
                      <template v-slot:field="{validationFailed}">
                        <Multiselect
                            placeholder="Введите название направления целевого использования"
                            class="field field--select" style="height: auto"
                            v-model="item.targetedUses"
                            :class="{unvalidate: validationFailed}"
                            mode="tags"
                            :searchable="true"
                            :aria-expanded="true"
                            :options="targetedUsesList"
                        />
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Ссылка на карточку ГИСП'"
                        placeholder="Введите URL адрес"
                        :ref="setRef"
                        url
                        notRequired
                        maxlength="255"
                        v-model="item.gispCardUrl">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Ссылка на карточку РФРП'"
                        placeholder="Введите URL адрес"
                        :ref="setRef"
                        url
                        notRequired
                        maxlength="255"
                        v-model="item.rfrpCardUrl">
                    </ValidateInput>
                  </div>
                  <div class="contract__item">
                    <ValidateInput
                        :name="'Срок займа, диапазон лет'"
                        required
                        placeholder="Введите диапазон срока займа"
                        :ref="setRef"
                        maxlength="50"
                        v-model="item.loanDuration">
                    </ValidateInput>
                    <div class="interval-fields">
                      <ValidateInput
                          :name="'Сумма займа, млн руб.'"
                          required
                          number
                          placeholder="Введите минимальную сумму"
                          maxlength="16"
                          :float-format="2"
                          :ref="setRef"
                          v-model="item.loanSumMin">
                      </ValidateInput>
                      <span></span>
                      <ValidateInput
                          :name="''"
                          required
                          number
                          placeholder="Введите максимальную сумму"
                          maxlength="16"
                          :float-format="2"
                          :ref="setRef"
                          v-model="item.loanSumMax">
                      </ValidateInput>
                    </div>
                    <ValidateInput
                        :name="'Ставка, %'"
                        required
                        number
                        placeholder="Введите ставку в процентах"
                        :ref="setRef"
                        :float-format="2"
                        maxlength="49"
                        v-model="item.rate">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Софинансирование, %'"
                        required
                        placeholder="Введите условия софинансирования"
                        maxlength="50"
                        :ref="setRef"
                        v-model="item.cofinanceRate">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Минимальный бюджет заявки, млн руб'"
                        required
                        placeholder="Введите минимальный бюджет заявки"
                        :ref="setRef"
                        maxlength="50"
                        v-model="item.commonBudget">
                    </ValidateInput>
                  </div>
                </div>
                <div class="contract__row" v-if="item.id">
                  <div class="entry-info entry-info--width50">
                    <div class="entry-info__item">Дата создания
                      записи:<span>{{ moment(item.createdAt).format('DD.MM.YYYY HH:mm:ss') }}</span></div>
                    <div class="entry-info__item">Создание записи, кем:<span>{{ item.creator.shortName }}</span>
                    </div>
                    <div class="entry-info__item">Дата изменения
                      записи:<span>{{ moment(item.modifiedAt).format('DD.MM.YYYY HH:mm:ss') }}</span></div>
                    <div class="entry-info__item">Изменение записи, кем:<span>{{ item.modifier.shortName }}
                                            </span></div>
                  </div>
                </div>
                <div class="buttons-block buttons-block--right">
                  <button type="button" class="button button--delete" v-if="item.id" @click="showRemove">
                    Удалить
                  </button>
                  <router-link :to="{ name: 'CardRegions', params: { id: region.id } }" class="button button--clear">
                    Отмена изменений
                  </router-link>
                  <button type="button" class="button" @click="save">сохранить</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document">
        <div class="popup popup-del">
          <div class="modal-title">Удаление записи</div>
          <div class="modal-body">Вы действительно хотите удалить самостоятельную программу?</div>
          <div class="modal-bottom">
            <div class="buttons-block button-dialog-center">
              <button type="button" class="button button--clear close-modal--js" @click="closeRemove">Закрыть</button>
              <button type="button" class="button button--delete sub-modal-open--js"
                      data-modal="close-document" @click="remove">Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="close-document">
        <div class="popup">
          <div class="modal-title">Запись удалена</div>
          <div class="modal-body"></div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear close-modal--js" @click="close">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped>

</style>