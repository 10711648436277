import JwtService from "@/common/jwt.service";
import {API_URL, GATEWAY_URL} from "@/common/config";
import axios from "axios";
import qs from "qs";

axios.defaults.baseURL = API_URL;

const ApiService = {
    setHeader() {
        if (JwtService.getToken() !== "" && JwtService.getToken() !== null) {
            axios.defaults.headers.common[
                "Authorization"
                ] = `Bearer ${JwtService.getToken()}`;
        } else {
            delete axios.defaults.headers.common.Authorization
        }
    },

    async query(resource, params) {
        this.setHeader();

        return axios.get(resource, params).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },
    
    get(resource, slug = "") {
        this.setHeader();
        
        return axios.get(`${resource}${slug}/`)
    },
    
    getBlob(resource, slug = "") {
        this.setHeader();
        
        return axios.get(`${resource}${slug}/`, { responseType: "blob" })
    },

    get2(resource, slug = "") {
        this.setHeader();

        return axios.get(`${resource}${slug}`).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    post(resource, params) {
        this.setHeader();

        return axios.post(`${resource}`, params);
    },

    postEncodedURI(resource, params) {
        return axios.post(`${resource}`, params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            baseURL: ""
        });
    },

    postForm(resource, form) {
        this.setHeader();

        return axios.postForm(`${resource}`, form);
    },

    postEncoded(resource, form) {
        this.setHeader();

        return axios.post(`${resource}`, form, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
    },

    update(resource, slug, params) {
        this.setHeader();

        return axios.put(`${resource}/${slug}`, params);
    },

    put(resource, params) {
        this.setHeader();

        return axios.put(`${resource}`, params);
    },

    delete(resource) {
        this.setHeader();

        return axios.delete(resource);
    }
};
export const Regions = {
    async list(params) {
        return ApiService.query("/regions/registry", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    async item(id) {
        return ApiService.get(`/regions/${id}`);
    },
    async permissions() {
        return ApiService.get(`/permissions`);
    },
    async statistics(id) {
        return ApiService.get(`/regions/statistics/${id}`);
    },
    async responsibilities(id) {
        return ApiService.get(`/regions/responsibilities/${id}`);
    },
    async projectManagers() {
        return ApiService.get(`/regions/project-managers`);
    },
    async regionalExperts() {
        return ApiService.get(`/regions/regional-experts`);
    },
    async updateResponsibilities(id, params) {
        return ApiService.put(`/regions/responsibilities/${id}/update`, params);
    },
    async documents(id) {
        return ApiService.get2(`/regions/documents/${id}`);
    },
    async districts() {
        return ApiService.get2(`/districts`);
    },
    async npaStatistics(id) {
        return ApiService.get2(`/regions/npa/${id}/statistics`);
    },
    async npaRegistry() {
        return ApiService.get2(`/regions/npa/registry`);
    },
    async npaContacts(id) {
        return ApiService.get(`/npa/contacts/${id}`);
    },
    async createNPAContact(id, params) {
        return ApiService.post(`/npa/contacts/${id}/create`, params);
    },
    async updateNPAContact(id, contactID, params) {
        return ApiService.put(`/npa/contacts/${id}/update/${contactID}`, params);
    },
    async removeNPAContact(id, contactID) {
        return ApiService.delete(`/npa/contacts/${id}/delete/${contactID}`);
    },
    async createDocument(id, params) {
        return ApiService.post(`/regions/documents/${id}/create`, params);
    },
    async updateDocument(id, docID, params) {
        return ApiService.put(`/regions/documents/${id}/update/${docID}`, params);
    },
    async removeDocument(id, docID) {
        return ApiService.delete(`/regions/documents/${id}/remove/${docID}`);
    },
}
export const Funds = {
    async item(id) {
        return ApiService.get(`/funds/${id}`);
    },
    async updateFunds(id, params) {
        return ApiService.put(`/funds/${id}/update`, params);
    },
    async contacts(id) {
        return ApiService.get(`/funds/contacts/${id}`);
    },
    async capitalization(id) {
        return ApiService.get(`/funds/capitalization-and-budget/${id}`);
    },
    async capitalizationStatistics(id) {
        return ApiService.get(`/funds/capitalization-and-budget/statistics/${id}`);
    },
    async collabPrograms(id) {
        return ApiService.get(`/funds/collab-programs/${id}`);
    },
    async createCollabProgram(id, params) {
        return ApiService.post(`/funds/collab-programs/${id}/create`, params);
    },
    async updateCollabProgram(id, pid, params) {
        return ApiService.put(`/funds/collab-programs/${id}/update/${pid}`, params);
    },
    async removeCollabProgram(id, pid) {
        return ApiService.delete(`/funds/collab-programs/${id}/remove/${pid}`);
    },
    async ownPrograms(id) {
        return ApiService.get(`/funds/own-programs/${id}`);
    },
    async createOwnPrograms(id, params) {
        return ApiService.post(`/funds/own-programs/${id}/create`, params);
    },
    async updateOwnPrograms(id, opID, params) {
        return ApiService.put(`/funds/own-programs/${id}/update/${opID}`, params);
    },
    async removeOwnPrograms(id, opID) {
        return ApiService.delete(`/funds/own-programs/${id}/remove/${opID}`);
    },
    async ownProgramsAgreements(id) {
        return ApiService.get(`/funds/own-programs/agreements/${id}/list`);
    },
    async ownProgramsCreateAgreement(id, params) {
        return ApiService.post(`/funds/own-programs/agreements/program/${id}/create`, params);
    },
    async ownProgramsUpdateAgreement(id, agreement, target, params) {
        return ApiService.post(`/funds/own-programs/agreements/program/${id}/update/${agreement}/${target}`, params);
    },
    async ownProgramAgreement(id) {
        return ApiService.get(`/funds/own-programs/agreements/agreement/${id}`);
    },
    async documents(id) {
        return ApiService.get(`/funds/documents/${id}`);
    },
    async createDocument(id, params) {
        return ApiService.post(`/funds/documents/${id}/create`, params);
    },
    async removeDocument(id, docID) {
        return ApiService.delete(`/funds/documents/${id}/remove/${docID}`);
    },
    async updateDocument(id, docID, params) {
        return ApiService.put(`/funds/documents/${id}/update/${docID}`, params);
    },
    async agreementStatistics(id) {
        return ApiService.get(`/funds/own-programs/agreements/${id}/statistics`);
    },
    async agreementRegistry(params) {
        return ApiService.query(`/funds/own-programs/agreements/registry`, {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    async agreementFundList(id) {
        return ApiService.get(`/funds/own-programs/agreements/${id}/list`);
    },
    async createCEO(id, params) {
        return ApiService.put(`/funds/${id}/ceo/create-or-update`, params);
    },
    async deleteCEO(id) {
        return ApiService.delete(`/funds/${id}/ceo/delete`);
    },
    async createCEOPhoto(id, params) {
        return ApiService.put(`/funds/${id}/ceo/photo/create-or-update`, params);
    },
    async deleteCEOPhoto(id) {
        return ApiService.delete(`/funds/${id}/ceo/photo/delete`);
    },
}
export const Rgo = {
    async item(id) {
        return ApiService.get(`/rgo/${id}`);
    },
    async update(id, rgoID, params) {
        return ApiService.put(`/rgo/${id}/update/${rgoID}`, params);
    },
    async create(id, params) {
        return ApiService.post(`/rgo/${id}/create`, params);
    },
    async createContact(id, params) {
        return ApiService.post(`/rgo/contacts/${id}/create`, params);
    },
    async updateContact(id, contactID, params) {
        return ApiService.put(`/rgo/contacts/${id}/update/${contactID}`, params);
    },
    async removeContact(id, contactID) {
        return ApiService.delete(`/rgo/contacts/${id}/delete/${contactID}`);
    },
    async counterparties(text) {
        return ApiService.query(`/counterparties/search`, {
            params: {
                text: text,
                charsCountActivation: 10
            },
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    async documents(id) {
        return ApiService.get2(`/rgo/documents/${id}`);
    },
    async createDocument(id, params) {
        return ApiService.post(`/rgo/documents/${id}/create`, params);
    },
    async removeDocument(id, docID) {
        return ApiService.delete(`/rgo/documents/${id}/remove/${docID}`);
    },
    async updateDocument(id, docID, params) {
        return ApiService.put(`/rgo/documents/${id}/update/${docID}`, params);
    },
    async updateSigningState(id, rgoId, params) {
        return ApiService.put(`/rgo/${id}/update-signing-state/${rgoId}`, params);
    },
}
export const Dictionary = {
    async legalForms() {
        return ApiService.get2(`/dictionaries/legal-forms`);
    },
    async collabPrograms() {
        return ApiService.get2(`/dictionaries/collab-programs`);
    },
    async supportedIndustries() {
        return ApiService.get2(`/dictionaries/supported-industries`);
    },
    async targetedUses() {
        return ApiService.get2(`/dictionaries/targeted-uses`);
    },
    async regulationsStatuses() {
        return ApiService.get2(`/dictionaries/regulations-statuses`);
    },
    async regulationsTypes() {
        return ApiService.get2(`/dictionaries/regulations-types`);
    },
    async fundCapitalizationYears() {
        return ApiService.get2(`/dictionaries/fund-capitalization-years`);
    },
    async rgoAccreditations() {
        return ApiService.get2(`/dictionaries/rgo-accreditations`);
    },
    async rgoAgreementStatuses() {
        return ApiService.get2(`/dictionaries/rgo-agreement-statuses`);
    },
    async rgoForm2021Statuses() {
        return ApiService.get2(`/dictionaries/rgo-form2021-statuses`);
    },
    async rgoGuaranteeAmountsYears() {
        return ApiService.get2(`/dictionaries/rgo-guarantee-amounts-years`);
    },
    async loanTargets() {
        return ApiService.get2(`/dictionaries/loan-targets`);
    },
}
export const FileDocument = {
    async fileTemp(form) {
        return ApiService.postForm(`/filestorage/file/temp`, form);
    },
    async fileData(id) {
        return ApiService.getBlob(`/filestorage/file/data/${id}`);
    },
    async fileMeta(id) {
        return ApiService.get(`/filestorage/file/meta/${id}`);
    },
    async fileDataBlob(id) {
        return ApiService.getBlob(`/filestorage/file/data/${id}`);
    },
}

export const Regulations = {
    async listNpa() {
        return ApiService.get(`/regions/npa/registry`);
    },
    async getNpa(regionId, regulationId) {
        return ApiService.get(`/regions/npa/${regionId}/${regulationId}`);
    },
    async createNpa(regionId, npaData) {
        return ApiService.post(`/regions/npa/${regionId}/create`, npaData);
    },
    async updateNpa(regionId, regulationId, npaData) {
        return ApiService.post(`/regions/npa/${regionId}/update/${regulationId}`, npaData);
    },
    async removeNpa(regionId, regulationId) {
        return ApiService.delete(`/regions/npa/${regionId}/remove/${regulationId}`);
    }
}
export const Capitalization = {
    async CapitalizationData(fundId) {
        return ApiService.get(`/funds/capitalization-and-budget/${fundId}`);
    },
    async FilteredCapitalizationData(fundId, year) {
        return ApiService.get(`/funds/capitalization-and-budget/${fundId}/${year}`);
    },
    async updateCapitalization(fundId, params) {
        return ApiService.post(`/funds/capitalization-and-budget/${fundId}/capitalization/update`, params);
    },
    async updateBudget(fundId, params) {
        return ApiService.post(`/funds/capitalization-and-budget/${fundId}/budget/update`, params);
    },
    async statisticsCapitalization(fundId) {
        return ApiService.get(`/funds/capitalization-and-budget/statistics/${fundId}`);
    },
    async createDocument(id, fundId, year, quarter, params) {
        return ApiService.post(`/funds/capitalization-and-budget/report/${fundId}/${year}/${quarter}/upload/${id}`, params);
    },
    async quarterDocuments(fundId, year, quarter) {
        return ApiService.get(`/funds/capitalization-and-budget/report/${fundId}/${year}/${quarter}`);
    },
}
export const MySelf = {
    async card() {
        return ApiService.get(`/me`);
    },
}
export const Gateway = {
    async get(resource, slug = "") {
        return axios.get(`${resource}${slug}`, {
            baseURL: GATEWAY_URL,
            headers: {
                Authorization: `Bearer ${JwtService.getToken()}`
            }
        })
    },
    async Counterparty(counterpartyId) {
        return Gateway.get(`/counterparty/?query=${counterpartyId}`);
    },
}

export default ApiService;
