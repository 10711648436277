<script setup>

import Shared from '@/common/shared'
import {Dictionary, Rgo} from '@/common/api.service'
import {toRefs, defineProps, ref, onMounted, computed} from 'vue'
import ValidateInput from '@/components/validate/input.vue'
import {useToast} from 'vue-toastification'

const toast = useToast()
const props = defineProps({
  rgo: {},
  item: {}
})
const {rgo, item} = toRefs(props)
const itemFields = ref([])
const setRef = (el) => {
  itemFields.value.push(el)
}
const phoneValid = ref(false)
const isLoading = ref(false)
const counterparties = ref([])

if (!rgo.value.id) {
  rgo.value.guaranteeAmounts = []
  Dictionary.rgoGuaranteeAmountsYears().then(({data}) => {
    data.forEach((v) => {
      rgo.value.guaranteeAmounts.push({year: v.value, value: NaN})
    })
  }).catch(() => {
  })
} else {
  Dictionary.rgoGuaranteeAmountsYears().then(({data}) => {
    rgo.value.guaranteeAmountsTemp = []
    data.forEach((v) => {
      let yearRecord = rgo.value.guaranteeAmounts.filter(r => r.year === v.value)
      if (yearRecord.length == 0) {
        rgo.value.guaranteeAmountsTemp.push({year: v.value, value: NaN})
      } else {
        rgo.value.guaranteeAmountsTemp.push({year: yearRecord[0].year, amount: yearRecord[0].amount})
      }
    })

    rgo.value.guaranteeAmounts = rgo.value.guaranteeAmountsTemp
    delete rgo.value.guaranteeAmountsTemp

    rgo.value.counterparty = {value: rgo.value.counterpartyId, label: rgo.value.counterpartyId}
    counterparties.value.push({value: {"value": rgo.value.counterparty.value}, label: rgo.value.counterparty.label})
  }).catch(() => {
  })
}

const counterpartyList = computed(() => {
  let r = []
  counterparties.value.forEach(function (f) {
    r.push({value: f.value, label: `${f.label}`})
  })
  return r
})

async function counterpartySearch(query) {
  isLoading.value = true
  counterparties.value = []
  if (query === '') {
    isLoading.value = false
    return
  }
  await Rgo.counterparties(query).then(({data}) => {
    data.forEach(function (f) {
      let label = `${f.title} ИНН: ${f.inn} ОГРН: ${f.ogrn}`
      let tempRecord = counterparties.value.filter(r => r.label === label)
      if (tempRecord.length == 0) {
        counterparties.value.push({value: f, label: label})
      }
    })
  })
  isLoading.value = false

}

async function save() {
  let isValid = true
  itemFields.value.forEach((f) => {
    f.validate()
    if (f.validationFailed) {
      isValid = false
    }
  })

  if (isValid) {
    if (!rgo.value.id) {
      await postNew()
      await close()
    } else {
      await post()
    }

  } else {
    toast.error('Форма не сохранена, проверьте обязательные поля', {
      timeout: 5000
    })
  }
}

async function close() {
  Shared.closeModal('#contact-form')
  Shared.closeModal('#del-contact-form')
  Shared.closeModal('#deleted-contact')
  rgo.value.modal = false
}

async function postNew() {
  let rgoGuaranteeAmounts = []
  rgo.value.guaranteeAmounts.forEach((v) => {
    rgoGuaranteeAmounts.push({key: v.year, value: v.amount})
  })

  await Rgo.create(item.value.region.id, {
    'counterpartyId': rgo.value.counterparty.id,
    'siteUrl': rgo.value.siteUrl,
    'generalEmail': rgo.value.generalEmail,
    'generalPhone': rgo.value.generalPhone,
    'loanLimit': rgo.value.loanLimit,
    'suppliedLoansCount': rgo.value.suppliedLoansCount,
    'rgoGuaranteeAmounts': rgoGuaranteeAmounts,
    'address': 'address',
    'ogrn': rgo.value.counterparty.ogrn
  })
  await Rgo.item(item.value.region.id)
      .then(({data}) => {
        item.value.rgo = data
        item.value.rgo.notCreated = false
      }).catch(() => {
      })
}

async function post() {
  if (rgo.value.counterparty && rgo.value.counterparty.value) {
    rgo.value.counterpartyId = rgo.value.counterparty.value
  }

  rgo.value.guaranteeAmounts.forEach((v) => {
    v.value = v.amount
  })
  rgo.value.rgoGuaranteeAmounts = rgo.value.guaranteeAmounts

  await Rgo.update(item.value.region.id, rgo.value.id, rgo.value).then(() => {
    toast.success("Форма РГО сохранена", {
      timeout: 5000
    })
    Rgo.item(item.value.region.id)
        .then(({data}) => {
          item.value.rgo = data
        }).catch(() => {
    })

    close()

  }).catch(({response}) => {
    toast.error(response.data.title, {
      timeout: 5000
    })
  })

  // await Rgo.item(item.value.region.id)
  //     .then(({data}) => {
  //       item.value.rgo = data
  //     }).catch(() => {
  //     })
}

async function setPhoneValidate(v) {
  phoneValid.value = v.valid
  //console.log(v,phoneValid.value)
}

async function phoneValidate(errors) {
  let index = errors.indexOf('phone unvalid')
  if (index !== -1) {
    errors.splice(index, 1)
  }
  if (!phoneValid.value) {
    errors.push('phone unvalid')
  }
}

onMounted(() => {
  Shared.showModal('#contact-form')
})

</script>

<template>
  <div class="modal-overlay" id="contact-form">
    <div class="popup popup-oy-auto">
      <div class="modal-title title-h3" v-if="rgo.id">Редактирование основной информации о РГО</div>
      <div class="modal-title title-h3" v-else>Добавление основной информации о РГО</div>
      <div class="modal-body">
        <label for="rgo-name" class="title-field title-field--relative">Название РГО
          <Multiselect class="field field--select c-input c-select c-filter__input c-multiselect-filter"
                       v-model="rgo.counterparty"
                       mode="single" :loading="isLoading" @search-change="counterpartySearch" :min-chars="10"
                       :show-no-results="false" :searchable="true" :options="counterpartyList"
                       placeholder="Название, ИНН или ОГРН организации"
                       :no-options-text="'Укажите название, ОГРН или ИНН'"/>
          <a href="https://olk.frprf.ru/counterparty/" class="field--add-icon-green link-select-item"
             target="_blank"></a>
        </label>
        <div class="table-container">
          <table class="table table--guarantee-capital">
            <thead>
            <tr>
              <th>Год</th>
              <th>Размер гарантийного капитала на г., млн руб.</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="i in rgo.guaranteeAmounts" :key="i.year">
              <td>{{ i.year }}</td>
              <td>
                <ValidateInput
                    :name="''"
                    number
                    placeholder="Введите размер гарантийного капитала на выбранный год"
                    :ref="setRef"
                    :float-format="2"
                    maxlength="15"
                    v-model="i.amount">
                </ValidateInput>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <label for="rgo-limit" class="title-field">Лимит на 1 заем, млн руб.
          <input class="field" type="text" v-model="rgo.loanLimit" placeholder="Введите лимит на 1 заем">
        </label>
        <ValidateInput
            :name="'Сайт'"
            placeholder="Введите сайт"
            :ref="setRef"
            v-model="rgo.siteUrl">
        </ValidateInput>
        <ValidateInput
            :name="'Общий e-mail'"
            placeholder="Введите адрес электронной почты"
            :ref="setRef"
            email
            v-model="rgo.generalEmail">
        </ValidateInput>
        <ValidateInput
            :name="'Телефонный номер'"
            placeholder="Введите номер телефона"
            :ref="setRef"
            required
            :validate-func="phoneValidate"
            v-model="rgo.generalPhone">
          <template v-slot:field="{validationFailed}">
            <vue-tel-input v-model="rgo.generalPhone"
                           class="field"
                           @validate="setPhoneValidate"
                           :dropdown-options="{showFlags: true, disabled: true}"
                           default-country="ru"
                           mode="international"
                           :style-classes="{unvalidate: validationFailed}"
                           :input-options="{placeholder:'Введите номер телефона'}"
            ></vue-tel-input>
          </template>
        </ValidateInput>
      </div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="reset" class="button button--clear" @click="close">отменить
          </button>
          <button type="submit" class="button" @click="save">СОХРАНИТЬ</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>