<script setup>

import Shared from '@/common/shared'
import {Rgo} from '@/common/api.service'
import {toRefs, defineProps, ref, onMounted} from 'vue'
import ValidateInput from '@/components/validate/input.vue'
import {useToast} from 'vue-toastification'

const toast = useToast()
const props = defineProps({
  contact: {},
  item: {}
})
const {contact, item} = toRefs(props)
const itemFields = ref([])
const setRef = (el) => {
  itemFields.value.push(el)
}
const phoneValid = ref(false)

async function save() {
  let isValid = true
  itemFields.value.forEach((f) => {
    f.validate()
    if (f.validationFailed) {
      isValid = false
    }
  })

  if (isValid) {
    if (!contact.value.id) {
      await postNew()
    } else {
      await post()
    }
    await close()
  } else {
    toast.error('Контакт не сохранен, проверьте обязательные поля', {
      timeout: 5000
    })
  }
}

async function close() {
  Shared.closeModal('#contact-form')
  Shared.closeModal('#del-contact-form')
  Shared.closeModal('#deleted-contact')
  contact.value.rgoModal = false
}

async function postNew() {
  await Rgo.createContact(item.value.rgo.id, contact.value)
  await Rgo.item(item.value.region.id)
      .then(({ data }) => {
        item.value.rgo = data
        item.value.rgo.notCreated = false
      }).catch(() => {
      })
}

async function post() {
  await Rgo.updateContact(item.value.rgo.id, contact.value.id, contact.value)
  await Rgo.item(item.value.region.id)
      .then(({ data }) => {
        item.value.rgo = data
      }).catch(() => {
      })
}

async function remove() {
  await Rgo.removeContact(item.value.rgo.id, contact.value.id)
  await Rgo.item(item.value.region.id)
      .then(({ data }) => {
        item.value.rgo = data
      }).catch(() => {
      })
  Shared.showModal('#del-contact-form')
  Shared.showModal('#deleted-contact')
}

async function showRemove() {
  Shared.closeModal('#contact-form')
  Shared.showModal('#del-contact-form')
}

async function setPhoneValidate(v) {
  phoneValid.value = v.valid
  //console.log(v,phoneValid.value)
}
async function phoneValidate(errors) {
  let index = errors.indexOf('phone unvalid')
  if (index !== -1) {
    errors.splice(index, 1)
  }
  if (!phoneValid.value) {
    errors.push('phone unvalid')
  }
}

onMounted(() => {
  Shared.showModal('#contact-form')
})

</script>

<template>
  <div class="modal-overlay" id="contact-form">
    <div class="popup">
      <div class="modal-title title-h2" v-if="!contact.id">Добавление контактного лица РГО</div>
      <div class="modal-title title-h2" v-else>Редактирование контактного лица РГО</div>
      <div class="modal-body">
        <ValidateInput
            :name="'ФИО'"
            placeholder="Введите ФИО"
            :ref="setRef"
            required
            v-model="contact.fullName">
        </ValidateInput>
        <ValidateInput
            :name="'Должность'"
            placeholder="Введите должность"
            :ref="setRef"
            required
            v-model="contact.occupation">
        </ValidateInput>
        <ValidateInput
            :name="'Номер телефона'"
            placeholder="Введите номер телефона"
            :ref="setRef"
            required
            :validate-func="phoneValidate"
            v-model="contact.phone">
          <template v-slot:field="{validationFailed}">
            <vue-tel-input v-model="contact.phone"
                           class="field"
                           @validate="setPhoneValidate"
                           :dropdown-options="{showFlags: true, disabled: true}"
                           default-country="ru"
                           mode="international"
                           :style-classes="{unvalidate: validationFailed}"
                           :input-options="{placeholder:'Введите номер телефона'}"
            ></vue-tel-input>
          </template>
        </ValidateInput>
        <ValidateInput
            :name="'E-mail'"
            placeholder="Введите адрес электронной почты"
            :ref="setRef"
            email
            required
            v-model="contact.email">
        </ValidateInput>
        <ValidateInput
            :name="'Комментарий'"
            textarea
            placeholder="Введите комментарий"
            :ref="setRef"
            v-model="contact.comment">
        </ValidateInput>
      </div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="submit" class="button button--delete" v-if="contact.id" @click="showRemove">
            Удалить
          </button>
          <button type="button" class="button button--clear" @click="close">закрыть
          </button>
          <button type="submit" class="button" @click="save">СОХРАНИТЬ</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-overlay" id="del-contact-form">
    <div class="popup">
      <div class="modal-title">Удаление контакта</div>
      <div class="modal-body">Вы действительно хотите удалить контакт?</div>
      <div class="modal-bottom button-dialog-center">
        <div class="buttons-block">
          <button type="button" class="button button--clear" @click="close">Закрыть
          </button>
          <button type="submit" class="button button--delete" @click="remove">Удалить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-overlay" id="deleted-contact">
    <div class="popup">
      <div class="modal-title">Контакт удален</div>
      <div class="modal-body"></div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="button" class="button" @click="close">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>