<script setup>

import Shared from '@/common/shared'
import {Dictionary, Funds} from '@/common/api.service'
import {toRefs, defineProps, ref, onMounted} from 'vue'
import ValidateInput from '@/components/validate/input.vue'
import moment from 'moment'
import {useToast} from 'vue-toastification'

const toast = useToast()
const props = defineProps({
  fund: {},
  item: {},
})
const {fund, item,} = toRefs(props)
const phoneValid = ref(false)
const itemFields = ref([])
const legalForms = ref([])
const setRef = (el) => {
  itemFields.value.push(el)
}

async function save() {
  let isValid = true
  itemFields.value.forEach((f) => {
    f.validate()
    if (f.validationFailed) {
      isValid = false
    }
  })

  if (fund.value.siteUrl === '') {
    delete fund.value.siteUrl
  }

  if (fund.value.agreementSigningDate){
    fund.value.agreementSigningDate = moment(fund.value.agreementSigningDate).format('YYYY-MM-DD')
  }

  fund.value.legalForm = parseInt(fund.value.legalFormTypes)

  if (isValid) {
      await post().then(async () => {
        await close()
      }).catch(async ({response}) => {
        toast.error(response.data.title, {
          timeout: 5000
        })
      })
  } else {
    toast.error('Основная информация о РФРП не сохранена, проверьте обязательные поля', {
      timeout: 5000
    })
  }
}

async function close() {
  Shared.closeModal('#edit-main-rfrp')
  fund.value.modal = false
}

async function post() {
  await Funds.updateFunds(fund.value.id, fund.value)
  await Funds.item(fund.value.id)
      .then(({data}) => {
        item.value.fund = data
      }).catch(() => {
      })
}

async function setPhoneValidate(v) {
  phoneValid.value = v.valid
  //console.log(v,phoneValid.value)
}
async function phoneValidate(errors) {
  let index = errors.indexOf('phone unvalid')
  if (index !== -1) {
    errors.splice(index, 1)
  }
  if (!phoneValid.value) {
    errors.push('phone unvalid')
  }
}

onMounted(async () => {
  await Dictionary.legalForms()
      .then(({ data }) => {
        legalForms.value = data
      }).catch(() => {
      })
  Shared.showModal('#edit-main-rfrp')
})

</script>

<template>
  <div class="modal-overlay" id="edit-main-rfrp">
    <div class="popup popup-oy-auto">
      <div class="modal-title title-h3">Редактирование основной информации о РФРП</div>
      <div class="modal-body">
        <ValidateInput
            :name="'Сайт'"
            url
            placeholder="Введите сайт"
            maxlength="255"
            :ref="setRef"
            v-model="fund.siteUrl">
        </ValidateInput>
        <ValidateInput
            :name="'Общий e-mail'"
            placeholder="Введите адрес электронной почты"
            :ref="setRef"
            email
            maxlength="255"
            v-model="fund.generalEmail">
        </ValidateInput>
        <ValidateInput
            :name="'Номер телефона'"
            placeholder="Введите номер телефона"
            :ref="setRef"
            required
            :validate-func="phoneValidate"
            v-model="fund.generalPhone">
          <template v-slot:field="{validationFailed}">
            <vue-tel-input v-model="fund.generalPhone"
                           class="field"
                           @validate="setPhoneValidate"
                           :dropdown-options="{showFlags: true, disabled: true}"
                           default-country="ru"
                           mode="international"
                           :style-classes="{unvalidate: validationFailed}"
                           :input-options="{placeholder:'Введите номер телефона'}"
            ></vue-tel-input>
          </template>
        </ValidateInput>
        <ValidateInput
            :name="'Организационно-правовая форма'"
            required
            :ref="setRef"
            select
            :items="legalForms"
            :item-key="'value'"
            :item-name="'title'"
            v-model="fund.legalFormTypes">
        </ValidateInput>
        <ValidateInput
            :name="'Фактический адрес'"
            placeholder="Введите адрес"
            maxlength="1024"
            :ref="setRef"
            v-model="fund.actualAddress">
        </ValidateInput>
        <ValidateInput
            :name="'Учредитель РФРП'"
            placeholder="Введите учредителя РФРП"
            maxlength="255"
            :ref="setRef"
            v-model="fund.legalAddress">
        </ValidateInput>
        <ValidateInput
            :name="'Реквизиты НПА о создании РФРП'"
            placeholder="Введите реквизиты НПА о создании РФРП"
            :ref="setRef"
            maxlength="1024"
            v-model="fund.npaRequisites">
        </ValidateInput>
        <label for="rfrp-date" class="title-field">Дата подписания соглашения
          <VueDatePicker placeholder="00.00.0000" class="field field--calendar"
                         v-model="fund.agreementSigningDate" />
        </label>
      </div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="reset" class="button button--clear" @click="close">отменить
          </button>
          <button type="submit" class="button" @click="save()">СОХРАНИТЬ</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>